import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import Button from 'components/CustomButtons/Button.jsx'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import firebase from '../firebase'

export default function Faq() {
  const [user, setUser] = useState(false)

  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user)
      } else {
        setUser(null)
      }
    })
    return () => unsubscribe()
  }, [])
  return (
    <Layout>
      <Wrapper>
        <Heading>Got some Questions?</Heading>
        <SubHeading>
          Please browse our frequently asked questions below, if you still can't
          find what you're looking for then please don't hesitate to{' '}
          <Link onClick={() => navigate('/contact')}>contact us</Link>.
        </SubHeading>

        <ButtonWrap>
          <>
            {user ? (
              <Button
                onClick={() => navigate('/editprofile')}
                round
                color="warning"
                size="lg"
              >
                <ButtonText>Apply as a freelancer</ButtonText>
              </Button>
            ) : (
              <Button
                onClick={() => navigate('/apply')}
                round
                color="warning"
                size="lg"
              >
                <ButtonText>Apply as a freelancer</ButtonText>
              </Button>
            )}
          </>
          <Button
            onClick={() => navigate('/hire')}
            round
            color="rose"
            size="lg"
          >
            <ButtonText>Hire Talent</ButtonText>
          </Button>
        </ButtonWrap>

        <QuestionText>Are there any up front costs?</QuestionText>
        <AnswerText>
          Only for hirers. Upon brief we take a deposit of € 299 that will be
          applied as a credit to your first invoice once you make a hire. If you
          do not hire, your deposit will be refunded completely.
        </AnswerText>

        <QuestionText>How does the free trial work?</QuestionText>
        <AnswerText>
          We will agree an initial 48 hour trial period (sometimes longer). This
          gives you time to ensure that you are happy with our selection. If you
          are happy, just continue and pay for the time. If you are not happy,
          you won’t be billed and we will either refund you or replace and
          commence another 48 hour trial.
        </AnswerText>

        <QuestionText>
          How does the Testing & Screening process work?
        </QuestionText>
        <AnswerText>
          Each Flexatal Freelancer is interviewed to test both their
          communication and language skills as well as their general
          competencies and personality.
        </AnswerText>
        <AnswerText>
          Upon passing this stage, our freelancers are technically tested, to
          ensure that they fit the top 10 percentile. Our testing process can be
          a specific skill based coding test, or a review of existing work /
          code base. Finally, for your reassurance we verify the freelancer with
          previous hirers.
        </AnswerText>

        <QuestionText>Where are your experts located?</QuestionText>
        <AnswerText>
          Globally! We believe that to build a truly world class team, you need
          to take geography out of the equation. Go where the best talent is!
        </AnswerText>

        <QuestionText>
          What happens if my consultant is unavailable or takes time off?
        </QuestionText>
        <AnswerText>
          We always try and manage time off request with reasonable notice. In
          the highly unlikely event that your consultant becomes unexpectedly
          unavailable, we will replace them. Note, you will only pay for time
          worked.
        </AnswerText>

        <QuestionText>Can I hire a Flexatal consultant directly?</QuestionText>
        <AnswerText>
          If all parties desire this, then absolutely – our conversion fee is
          detailed in your terms and conditions.
        </AnswerText>
        <QuestionText>Do you have a rate card?</QuestionText>
        <AnswerText>
          Consultants set their own prices and we charge a fixed amount on top
          of this. Prices vary in relation to location, skill and experience
          level. However, we will consult with you on your budget and then
          ensure that we find you an expert within this range.
        </AnswerText>
        <QuestionText>How does the invoicing process work?</QuestionText>
        <AnswerText>
          Freelancers complete our online timesheet and we invoice you
          accordingly on a monthly basis with 14 day payment terms.
        </AnswerText>

        <ButtonWrap>
          <>
            {user ? (
              <Button
                onClick={() => navigate('/editprofile')}
                round
                color="warning"
                size="lg"
              >
                <ButtonText>Apply as a freelancer</ButtonText>
              </Button>
            ) : (
              <Button
                onClick={() => navigate('/apply')}
                round
                color="warning"
                size="lg"
              >
                <ButtonText>Apply as a freelancer</ButtonText>
              </Button>
            )}
          </>
          <Button
            onClick={() => navigate('/hire')}
            round
            color="rose"
            size="lg"
          >
            <ButtonText>Hire Talent</ButtonText>
          </Button>
        </ButtonWrap>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  background-color: #232323;
  display: flex;
  padding: 5% 0;
  flex-direction: column;
`
const Link = styled.button`
  font-size: 2.5vh;

  font-weight: bold;
  color: #ff9000;
  background-color: transparent;
  border: none;
`

const Heading = styled.h1`
  font-size: 8vh;
  font-weight: bold;
  padding: 0 5%;
  color: white;
`

const SubHeading = styled.h3`
  font-size: 2.5vh;
  font-weight: bold;
  padding: 0 5%;
  color: white;
`

const QuestionText = styled.h3`
  font-size: 3.5vh;
  font-weight: bold;
  padding: 0 5%;
  color: white;
`

const AnswerText = styled.p`
  font-size: 2vh;
  padding: 0 5%;
  color: white;
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 5%;
  width: 50%;
  @media (max-width: 700px) {
    flex-direction: column;
    width: 100%;
  }
`

const ButtonText = styled.p`
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  margin: 0;
  @media (max-width: 400px) {
    font-size: 16px;
  }
`
